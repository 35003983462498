<template>
  <Modal
    :showing="modalType === 'detail'"
    @close="closeModal"
    :showClose="modalType === 'detail'"
    :backgroundClose="true"
    size="max-w-4xl"
  >
    <template v-if="value?.attributes">
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Detail Ekspedisi
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data ekspedisi yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Kode Ekspedisi</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ value.attributes.code }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Nama Ekspedisi</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ value.attributes.name }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Keterangan</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ value.attributes.description }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Alamat 1</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ value.attributes.address_1 }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Alamat 2</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ value.attributes.address_2 }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ value.attributes.createdAt | formatDate }}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-bold text-gray-700">Terakhir Diperbarui</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ value.attributes.updatedAt | formatDate }}
            </dd>
          </div>
        </dl>
      </div>
      <Datatable
        :total="getExpeditionDetails.meta.page.total"
        :perPage="getExpeditionDetails.meta.page.perPage"
        :currentPage="getExpeditionDetails.meta.page.currentPage"
        @pagechanged="onPageChange"
        :is-empty="!getExpeditionDetails.data?.length"
      >
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                v-for="item in [
                  {
                    label: 'Alamat',
                  },
                  {
                    label: 'No Telp',
                  },
                  {
                    label: '',
                    class: 'w-10',
                  },
                ]"
                scope="col"
                :key="item"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                :class="item.class"
              >
                {{ item.label }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody v-if="getExpeditionDetails.data?.length > 0">
            <tr
              class="bg-white hover:bg-green-100"
              v-for="(data, dataIdx) in getExpeditionDetails.data"
              :key="data.id"
              :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.attributes.address }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{
                  data.attributes.phone?.length > 0
                    ? data.attributes.phone.join(', ')
                    : null
                }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                <div class="flex space-x-2">
                  <!-- <base-button
                  for-icon
                  class="child text-yellow-400 hover:text-yellow-600"
                  @click="editExpedition(data)"
                  type="button"
                >
                  <Icon icon="heroicons:pencil-square" class="h-5 w-5" />
                </base-button> -->
                  <!-- <base-button
                  for-icon
                  class="child text-red-400 hover:text-red-600"
                  @click="removeExpedition(data)"
                  type="button"
                >
                  <Icon icon="heroicons:trash" class="h-5 w-5" />
                </base-button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </Datatable>
      <div class="mx-8 flex justify-end">
        <base-button @click="closeModal" type="button"> Tutup </base-button>
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/base/BaseModal';
import Datatable from '@/components/base/Datatable';

export default {
  name: 'DetailExpeditionModal',
  components: {
    Modal,
    Datatable,
  },
  props: {
    errors: {
      type: Object,
    },
    modalType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      getExpedition: 'expeditions/getExpedition',
      getExpeditionDetails: 'expedition_details/getExpeditionDetails',
    }),
    value() {
      return this.getExpedition.data;
    },
  },
  methods: {
    closeModal(value) {
      this.$emit('close', value);
    },
    onPageChange(page) {
      console.log({ page });
    },
    editExpedition() {},
    removeExpedition() {},
  },
};
</script>
