<template>
  <div>
    <base-modal
      :showing="value"
      @close="onClose"
      :showClose="value"
      :backgroundClose="true"
      size="max-w-4xl"
      overflowable
    >
      <div
        class="mb-6 flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
      >
        <div>
          <h3 class="text-lg font-semibold leading-6 text-gray-900">
            {{ value.id ? 'Edit Ekspedisi' : 'Tambah Ekspedisi' }}
          </h3>
        </div>
      </div>
      <base-form
        @submit="createOrUpdateExpedition"
        id="modal-add"
        :form="expedition"
        :nullable="nullable"
        v-slot="{ errors }"
      >
        <div class="mt-6 flex flex-col space-y-4">
          <div class="flex w-full">
            <div class="relative flex w-full items-center space-x-4">
              <base-input
                type="text"
                label="Kode Ekspedisi"
                id="kode_ekspedisi"
                placeholder="Masukkan kode ekspedisi"
                inset
                with-label
                fullwidth
                :invalid="errors.name"
                :message="errors.name"
                v-model="expedition.data.attributes.code"
              />
              <button
                @click="handleSearchButton"
                class="absolute right-4 text-gray-600"
                type="button"
              >
                <Icon
                  icon="heroicons:magnifying-glass-circle-20-solid"
                  class="h-5 w-5"
                />
              </button>
            </div>
          </div>
          <div class="flex sm:flex-row sm:gap-x-4">
            <base-input
              type="text"
              label="Nama Ekspedisi"
              id="nama_ekspedisi"
              placeholder="Masukkan nama ekspedisi"
              inset
              with-label
              fullwidth
              :invalid="errors.name"
              :message="errors.name"
              v-model="expedition.data.attributes.name"
            />
            <base-input
              type="text"
              label="Deskripsi Ekspedisi"
              id="deskripsi_ekspedisi"
              placeholder="Masukkan Deskripsi Ekspedisi"
              inset
              with-label
              fullwidth
              :invalid="errors.description"
              :message="errors.description"
              v-model="expedition.data.attributes.description"
            />
          </div>

          <div class="relative flex w-full items-center space-x-4">
            <base-input
              type="text"
              label="Alamat Ekspedisi"
              id="alamat"
              placeholder="Masukkan Alamat Ekspedisi"
              inset
              with-label
              fullwidth
              :invalid="errors.name"
              :message="errors.name"
              v-model="expeditionDetail.data.attributes.address"
            />
            <button
              @click="handleSearchExpeditionDetailButton"
              class="absolute right-4 text-gray-600"
              type="button"
            >
              <Icon
                icon="heroicons:magnifying-glass-circle-20-solid"
                class="h-5 w-5"
              />
            </button>
          </div>
          <base-input-tag
            v-model="expeditionDetail.data.attributes.phone"
            @updating="(val) => (expeditionDetail.data.attributes.phone = val)"
            id="phone"
            placeholder="Masukkan no telepon"
          />
          <div class="mb-4 flex justify-end">
            <base-button type="button" @click="onSubmit">
              Simpan Alamat
            </base-button>
          </div>
        </div>
        <div class="pt-5"></div>
      </base-form>
    </base-modal>
    <success-modal
      v-model="modalSuccessVisible"
      @close="() => (modalSuccessVisible = !modalSuccessVisible)"
      @finish="() => (modalSuccessVisible = !modalSuccessVisible)"
    />
    <expedition-modal
      :visible="modalExpeditionVisible"
      @change="handleChangeExpedition"
    />
    <expedition-detail-modal
      v-model="modalExpeditionDetailVisible"
      @change="handleChangeExpeditionDetail"
      @close="() => (modalExpeditionDetailVisible = false)"
    />
  </div>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseForm from '@/components/base/BaseForm.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseModal from '@/components/base/BaseModal';
// import BaseSelectSearch from '@/components/base/BaseSelectSearch.vue';
import { mapGetters, mapActions } from 'vuex';
// import BaseStepperForm from '@/components/base/BaseStepperForm.vue';
import BaseInputTag from '@/components/base/BaseInputTag.vue';
import { clone } from '@/services/utils.service.js';
import SuccessModal from '@/components/modal/success-modal.vue';
import ExpeditionModal from '@/components/modal/expedition-modal.vue';
import ExpeditionDetailModal from '@/components/modal/expedition-detail-modal.vue';
// import AddressForm from '@/components/stockist/create-mitra-form/address-form.vue';

const ExpeditionInterface = {
  data: {
    type: 'expeditions',
    attributes: {
      name: '',
      code: '',
      description: '',
    },
    // relationships: {
    //   city: {
    //     data: {
    //       type: 'cities',
    //       id: '',
    //     },
    //   },
    // },
  },
};

const ExpeditionDetailInterface = {
  data: {
    type: 'expedition-details',
    attributes: {
      address: '',
      phone: [],
    },
    relationships: {
      expedition: {
        data: {
          type: 'expeditions',
          id: '',
        },
      },
    },
  },
};

export default {
  name: 'CreateExpeditionModal',
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    BaseModal,
    BaseInputTag,
    SuccessModal,
    ExpeditionModal,
    ExpeditionDetailModal,
  },
  props: {
    errors: {
      type: Object,
    },
    value: {
      type: [Boolean, Object],
    },
  },
  data() {
    return {
      isCreatingExpedition: false,
      isCreatingExpeditionDetail: false,
      modalExpeditionVisible: false,
      modalExpeditionDetailVisible: false,
      modalSuccessVisible: false,
      activeStep: 0,
      steps: [
        {
          label: 'Pilih Ekspedisi',
        },
        {
          label: 'Pilih Alamat',
        },
      ],
      expedition: clone(ExpeditionInterface),
      expeditionDetail: clone(ExpeditionDetailInterface),
      expeditionFilter: {
        search: {},
      },
      expeditionDetailFilter: {
        search: {},
      },
      expedidtionParams: {
        'filter[search]': '',
        'page[size]': 5,
        'page[number]': 1,
        include: 'city',
      },
      selectedProvince: null,
      selectedCity: null,
    };
  },
  computed: {
    ...mapGetters({
      getExpeditions: 'expeditions/getExpeditions',
      getExpedition: 'expeditions/getExpedition',
      getLoadingExpedition: 'expeditions/getLoading',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getLoadingExpeditionDetail: 'expedition_details/getLoading',
      getExpeditionDetails: 'expedition_details/getExpeditionDetails',
    }),
    nullable: function () {
      return {
        service: null,
        officeCategory: {
          id: '',
          name: '',
        },
        office: {
          id: '',
          attributes: {
            code: '',
          },
        },
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
          area: '',
        },
      };
    },
  },
  methods: {
    ...mapActions({
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchExpedition: 'expeditions/fetchExpedition',
      fetchExpeditions: 'expeditions/fetchExpeditions',
      createExpedition: 'expeditions/createExpedition',
      updateExpedition: 'expeditions/updateExpedition',
      fetchExpeditionDetails: 'expedition_details/fetchExpeditionDetails',
      createExpeditionDetail: 'expedition_details/createExpeditionDetail',
      updateExpeditionDetail: 'expedition_details/updateExpeditionDetail',
      fetchExpeditionDetailsByExpedition:
        'expedition_details/fetchExpeditionDetailsByExpedition',
    }),
    searchExpedition(value) {
      this.fetchExpeditions({
        params: {
          'filter[search]': value,
          'page[size]': 5,
          'page[number]': 1,
          include: 'city',
        },
      }).then((response) => {
        this.selectedCity = response.data.data[0].relationships.city.data.id;
        this.fetchProvinces({
          city_id: this.selectedCity,
          withoutParams: true,
        }).then((response) => {
          this.selectedProvince = response.data.data.id;
          this.fetchCities({ province_id: this.selectedProvince });
        });
      });
    },
    handleSearchButton() {
      this.modalExpeditionVisible = true;
    },
    changeCity() {},
    changeProvince() {
      this.fetchCities({
        province_id: this.selectedProvince,
        pageNumber: 1,
      });
    },
    onClose(value) {
      this.$emit('close', value);
    },
    handleSelectExpedition() {
      this.fetchExpedition({ id: '' });
    },
    changeExpedition() {
      this.isCreatingExpedition = false;
      this.expedition.data = this.expeditionFilter.search;
      this.expedition.data.attributes.code =
        this.expeditionFilter.search.attributes.code;
      this.fetchExpeditionDetailsByExpedition({
        id: this.expeditionFilter.search.id,
      });
    },
    changeExpeditionDetail() {
      this.isCreatingExpeditionDetail = false;
      this.expeditionDetail.data = this.expeditionDetailFilter.search;
    },
    async onSubmit() {
      await this.createOrUpdateExpedition();
      await this.createOrUpdateExpeditionDetail();
    },
    createOrUpdateExpeditionDetail() {
      if (!this.expeditionDetail.data?.relationships?.data?.id) {
        this.expeditionDetail.data.relationships = {
          expedition: {
            data: {
              id: this.getExpedition.data.id,
              type: 'expeditions',
            },
          },
        };
      }
      if (this.expeditionDetail.data.id) {
        this.updateExpeditionDetail(this.expeditionDetail).then((res) => {
          if (res) {
            this.fetchExpeditions();
            this.modalExpeditionVisible = false;
            this.modalExpeditionDetailVisible = false;
            this.onClose();
          }
        });
      } else {
        this.createExpeditionDetail(this.expeditionDetail).then((res) => {
          if (res) {
            this.fetchExpeditions();
            this.modalExpeditionVisible = false;
            this.modalExpeditionDetailVisible = false;
            this.onClose();
          }
        });
      }
    },
    createOrUpdateExpedition() {
      if (this.expedition.data.id) {
        this.expedition.data.relationships = undefined;
        return this.updateExpedition(this.expedition).then((res) => {
          if (res) {
            this.activeStep++;
            this.fetchExpeditions();
          }
        });
      } else {
        return this.createExpedition(this.expedition).then((res) => {
          if (res) {
            this.activeStep++;
            this.fetchExpeditions();
            this.modalSuccessVisible = true;
          }
        });
      }
    },
    async handleSearchExpeditionDetailButton() {
      await this.fetchExpeditionDetails({
        expedition_id: this.getExpedition.data.id,
      });
      this.modalExpeditionDetailVisible = true;
    },
    creatingExpedition() {
      this.isCreatingExpedition = true;
      this.expeditionFilter.search = {};
      this.expedition = clone(ExpeditionInterface);
    },
    onInput(val) {
      console.log({ val });
    },
    async handleChangeExpedition(expedition) {
      this.expedition.data = expedition;
      await this.createOrUpdateExpedition();
      this.modalExpeditionVisible = false;
    },
    handleChangeExpeditionDetail(expeditionDetail) {
      this.expeditionDetail.data = expeditionDetail;
      this.modalExpeditionDetailVisible = false;
    },
  },
  created() {
    if (this.getExpedition.data?.id) {
      this.expedition = this.getExpedition;
    }
    this.fetchProvinces({
      pageNumber: 1,
      fields: {
        provinces: 'name',
      },
    });
  },
};
</script>
