<template>
  <Modal
    :showing="value"
    @close="onClose"
    :showClose="value"
    :backgroundClose="true"
    size="max-w-6xl"
  >
    <div class="flex justify-between px-4 py-5 sm:px-6">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">Daftar Alamat</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Berdasarkan data Alamat yang telah dibuat
        </p>
      </div>
      <div>
        <img src="@/assets/images/logo.png" alt="" class="w-52" />
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end">
        <base-search
          placeholder="Cari Kode"
          v-model="search"
          @input="debounceSearchProduct"
        />
      </div>
      <div class="flex my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable
            :total="getExpeditionDetails.meta.page.total"
            :perPage="getExpeditionDetails.meta.page.perPage"
            :currentPage="getExpeditionDetails.meta.page.currentPage"
            @pagechanged="onPageChangeProducts"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Alamat Ekspedisi
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    No Telp
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getExpeditionDetails.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="changeProduct(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.address }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.phone?.join(', ') }}
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        @click="value = !value"
        type="button"
        class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
      >
        Tutup
      </button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/base/BaseModal';
import Datatable from '@/components/base/Datatable';
import debounce from 'debounce';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'ExpeditionDetailModal',
  components: {
    Modal,
    Datatable,
    BaseSearch,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    ...mapGetters({
      getExpeditionDetails: 'expedition_details/getExpeditionDetails',
    }),
  },
  methods: {
    debounceSearchProduct: debounce(function () {
      this.$emit('search', this.search);
    }, 300),
    onPageChangeProducts(value) {
      this.$emit('page-change', value);
    },
    changeProduct(value) {
      this.$emit('change', value);
    },
    getRelationshipById(id) {
      let data = this.getExpeditionDetails.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
