<template>
  <Modal
    :showing="visible"
    @close="visible = false"
    :showClose="visible"
    :backgroundClose="true"
    size="max-w-6xl"
  >
    <div class="flex justify-between px-4 py-5 sm:px-6">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Daftar Ekspedisi
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Berdasarkan data ekspedisi yang telah dibuat
        </p>
      </div>
      <div>
        <img src="@/assets/images/logo.png" alt="" class="w-52" />
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end">
        <base-search v-model="search" @change="debounceSearch" />
      </div>
      <div class="flex my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable
            :total="getExpeditions.meta.page.total"
            :perPage="getExpeditions.meta.page.perPage"
            :currentPage="getExpeditions.meta.page.currentPage"
            @pagechanged="onPageChangeProducts"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode Ekspedisi
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Nama Ekspedisi
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Deskripsi
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getExpeditions.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="changeProduct(data)"
                >
                  <td
                    class="flex items-center whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.code }}
                    <span v-if="data.attributes.is_global" class="tooltip">
                      <Icon
                        class="h-5 w-5 text-blue-600"
                        icon="heroicons:check-badge-20-solid"
                      />
                      <p class="tooltiptext">Terverifikasi NASA</p>
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.description }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  ></td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        @click="visible = !visible"
        type="button"
        class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
      >
        Tutup
      </button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/base/BaseModal.vue';
import Datatable from '@/components/base/Datatable.vue';
import BaseSearch from '@/components/base/Search.vue';
import debounce from 'debounce';

export default {
  name: 'ExpeditionModal',
  components: {
    Modal,
    Datatable,
    BaseSearch,
  },
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    ...mapGetters({
      getExpeditions: 'expeditions/getExpeditions',
    }),
  },
  methods: {
    debounceSearch: debounce(function () {
      this.$emit('search', this.search);
    }, 300),
    onPageChangeProducts(value) {
      this.$emit('page-change', value);
    },
    changeProduct(value) {
      this.$emit('change', value);
    },
    getRelationshipById(id) {
      let data = this.getExpeditions.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
};
</script>
