<template>
  <div @click="handleInput" class="tag-input rounded-md text-sm font-light">
    <div
      v-for="(tag, index) in tags"
      :key="tag"
      class="tag-input__tag bg-green-600"
    >
      {{ tag }}
      <span class="rotate-45" @click="removeTag(index)">+</span>
    </div>
    <input
      type="text"
      ref="inputEl"
      :placeholder="placeholder"
      class="tag-input__text mx-0 h-10 border-transparent px-0 text-sm focus:border-transparent focus:ring-0"
      style="width: 200px"
      @keydown.enter="addTag"
      @change="addTag"
      @keydown.tab.prevent="addTag"
      @keydown.space.prevent="addTag"
      @keydown.188="addTag"
      @keydown.delete="removeLastTag"
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Masukkan data...',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    tags: {
      get() {
        return this.value ?? [];
      },
      set(newVal) {
        this.$emit('updating', newVal);
      },
    },
  },
  methods: {
    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.tags.length >= 1) {
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i] === val) {
              return false;
            }
          }
        }
        this.tags.push(val);
        event.target.value = '';
      }
      this.$emit('change', this.tags);
    },
    removeTag(index) {
      this.tags.splice(index, 1);
      this.$emit('change', this.tags);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
      }
      this.$emit('change', this.tags);
    },
    handleInput() {
      this.$refs.inputEl.focus();
    },
  },
};
</script>

<style scoped>
a {
  position: absolute;
  right: 15px;
  bottom: 15px;
  text-decoration: none;
  color: #00003a;
  font-size: 20px;
}

/*tag input style*/

.tag-input {
  border: 1px solid #d9dfe7;
  background: #fff;
  padding: 0 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  background: none;
}
</style>
